import React from "react"
import { Segment, Grid } from "semantic-ui-react"
import { Link } from "gatsby"
import HeaderComponent from "../common/header"

const PortfolioBanner = ({ portfolio }) => {
  return (
    <Segment
      inverted
      textAlign="center"
      vertical
      // style={{
      //   background: "white",
      //   height: "450px",
      // }}
    >
      <HeaderComponent page="gallery"/>
      {/* <Grid divided="vertically" style={{width:'100%' ,marginTop:'100px'}}>
        <Grid.Row columns={2}>
          <Grid.Column className="product-banner-left">
            <p className="project-banner-blurb">Project</p>
            <p className="project-banner-title">{portfolio.name}</p>
          </Grid.Column>
          <Grid.Column className="product-banner-right">
            <Link to="/contact">
              <button className="contact-button" style={{position:'absolute',right:'14.6%'}}>CONTACT US</button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid> */}
    </Segment>
  )
}

export default PortfolioBanner
