import React from "react"
import { graphql } from "gatsby";
import PortfolioBanner from "./portfolioBanner"
import PortfolioMain from "./portfolioMain"
import Footer from "../common/footer"
import "semantic-ui-css/semantic.min.css"
import "../../styles/index.css"

const PortfolioDetail = ({ data }) => {
  const portfolio = data.contentfulPortfolios
  return (
    <div>
      <PortfolioBanner portfolio={portfolio}/>
      <PortfolioMain portfolio={portfolio}/>
      <Footer />
    </div>
  )
}
export default PortfolioDetail

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPortfolios(slug: { eq: $slug }) {
      slug
      name
      bannerImages {
        id
        fluid(maxWidth: 4000,quality:100) {
          src
        }
      }
      designerTitle 
      designerParagraph {
        designerParagraph
      }
      designerImage {
        id
        fluid(maxWidth: 4000,quality:100) {
          src
        }
      }
      designerWebsitePdf {
        title
        file {
          url
        }
      }
      portfolioLongImage {
        id
        fluid(maxWidth: 4000,quality:100) {
          src
        }
      }
    }
  }
`;