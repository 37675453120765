import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import React, { Fragment } from "react"
import { Grid, Image, Button } from "semantic-ui-react"
import Slider from "react-slick"
// import { Link } from "gatsby"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,

  // customize dot css here!
  // appendDots: dots => (
  //   <div
  //     style={{
  //       backgroundColor: "#ddd",
  //       borderRadius: "10px",
  //       padding: "10px"
  //     }}
  //   >
  //     <ul style={{ margin: "0px" }}> {dots} </ul>
  //   </div>
  // ),
  // customPaging: i => (
  //   <div
  //     style={{
  //       width: "30px",
  //       color: "blue",
  //       border: "1px blue solid"
  //     }}
  //   >
  //     {i + 1}
  //   </div>
  // )
}

const PortfolioMain = ({ portfolio }) => (
  (
    <Fragment>
      <Slider {...settings}>
        {portfolio.bannerImages.map(image => (
          <Image
            src={image.fluid.src}
            key={image.id}
            className="slider-image"
          />
        ))}
      </Slider>

      <Image
        src={portfolio.portfolioLongImage.fluid.src}
        key={portfolio.portfolioLongImage.id}
        className="portfolio-longImage"
      />

      <Grid divided="vertically" className="designerpick">
        <Grid.Row>
          <Image src={portfolio.designerImage.fluid.src} />
          <div className="designerpick-right">
            <div className="designerpick-content">
              <h1>{portfolio.designerTitle}</h1>
              <p>{portfolio.designerParagraph.designerParagraph}</p>
            </div>
            <span className="designerpick-text">COLLECTION</span>
            <span>
              <a
                href={portfolio.designerWebsitePdf.file.url}
                target="_blank"
                download
                rel="noopener noreferrer"
              >
                <Button>DOWNLOAD</Button>
              </a>
            </span>
          </div>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
)

export default PortfolioMain
